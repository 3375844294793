import React, { useState } from 'react';
import { Button, Modal, Form, Input } from 'antd';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { useGetTest, useGetTagsByRequest } from "../../ai/hook/";
import { useCreateTask } from '../../task/hooks/useCreateTask';
import { LoaderOnboardingComponent, useTrackClick } from "../../common";
import { noop } from 'lodash';

export const QuizGeneratorContainer = (props) => {
    const {
        event,
        setEvent = noop,
        inProgress,
        setInProgress,
        curriculum,
        tagIds,
        classes,
        workspace,
        disabled,
    } = props;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const trackClick = useTrackClick();
    const { getTestAi } = useGetTest();
    const { getTagsByRequest } = useGetTagsByRequest();
    const history = useHistory();
    const { createTask } = useCreateTask();
    const { formatMessage } = useIntl();

    const showModal = () => {
        setIsModalVisible(true);
        form.setFieldsValue({
            title: event.title,
            lessonPrompt: curriculum,
        });
    };

    const handleOk = async () => {
        const values = await form.validateFields();
        setIsModalVisible(false);
        generateQuiz(values.title, values.lessonPrompt);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const generateQuiz = async (title, lessonPrompt) => {
        trackClick(`click-quiz`, 'form-quiz', `https://unschooler.me/`);
        setInProgress(true);

        let tags = tagIds;
        if (!tagIds?.length) {
            tags = await getTagsByRequest(lessonPrompt);
            tags = tags || [];
        }
        try {
            let object = await getTestAi(lessonPrompt, title, '');
            if (object) {
                handleCreateTask(object, lessonPrompt, tags);
                setInProgress(false);
            } else {
                setInProgress(false);
                throw new Error('Failed to generate quiz');
            }
        } catch (error) {
            setInProgress(false);
            console.error('Error generating quiz:', error);
        }
    };

    const handleCreateTask = async (object, goal, tags) => {
        let taskData = {
            title: object?.title,
            description: object?.description,
            tagIds: tags,
            isFree: false,
            isDraft: false,
            questions: object?.questions,
            source: goal,
            workspaceId: workspace?.id,
            ...event && { taskInEvents: [{ eventId: event?.id }] },
        };

        let taskFromDB = await createTask({
            variables: {
                taskData,
            },
        });
        setEvent(p => ({ ...p, tasks: [...p.tasks, taskFromDB.data.createTask] }));
        history.push(`/tutorials/${taskFromDB.data.createTask.id}`);
    };

    return (
        <>
            <Button
                type="text"
                size="small"
                onClick={showModal}
                className={classes}
                disabled={disabled}
            >
              + {formatMessage({id: 'quiz_generate'})}
            </Button>
            <Modal title="Quiz Details" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Form form={form} layout="vertical">
                    <Form.Item name="title" label="Title" rules={[{ required: true, message: 'Please input the title!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="lessonPrompt" label="Lesson Prompt" rules={[{ required: true, message: 'Please input the lesson prompt!' }]}>
                        <Input.TextArea autoSize={{ minRows: 3, maxRows: 7 }} />
                    </Form.Item>
                </Form>
            </Modal>
            {inProgress && (
                <LoaderOnboardingComponent
                    type="overlay"
                    label={inProgress.message}
                />
            )}
        </>
    );
};